import './App.less';
import { Layout } from 'antd';
import banner from '../assets/banner.png';
import body from '../assets/body.png'
const { Header, Content } = Layout;
function App() {

  return (
    <>
      <Layout >
        <Header >
          <img className='logo' src='https://jhzo-1308086863.cos.ap-beijing.myqcloud.com/admin/static/30x110%E8%81%9A%E5%A5%BD%E8%B5%9Alogo-20240709.png' alt='logo'></img>
        </Header>
        <Content >
          <img className='banner' src={banner} alt='banner'></img>
          <img className='body' src={body} alt='body'></img>
          <div className='text'>
            <span onClick={()=> window.open('https://beian.miit.gov.cn/#/Integrated/index')} >京ICP备2022011796号-2</span>
          </div>
        </Content>
      </Layout>

    </>
  );
}

export default App;
